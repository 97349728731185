import timeout from '../utils/timeout';
import preconnect from '../utils/preconnect';
import { setLoginFlowState } from '@vgno/utils';

/* eslint-disable camelcase */
async function initSpid() {
    const [spid, configLoader] = await Promise.all([
        import(
            /* webpackChunkName: "account-sdk-browser" */
            '@schibsted/account-sdk-browser'
        ),
        import(
            /* webpackMode: "eager" */
            `./config/${process.env.NODE_ENV}`
        ),
    ]);

    const { Identity, Monetization } = spid;
    const { default: config } = configLoader;

    preconnect(
        'https://session-service.payment.schibsted.no',
        'use-credentials',
    );

    window.SPiD_Identity = new Identity(config);
    window.Identity = window.SPiD_Identity;
    window.SPiD_Monetization = new Monetization(config);
    window.SPiD_Identity.enableVarnishCookie();

    const event = new window.CustomEvent('identity-initialized', {
        detail: {
            ready: true,
        },
    });

    window.dispatchEvent(event);
    window.Identity.on('logout', () => {
        localStorage.setItem('vg-autologin', Date.now().toString());
    });
}

let sdkPromise;
export const getAccountSdk = () => {
    if (!sdkPromise) {
        sdkPromise = window.SPiD_Identity
            ? Promise.resolve(window.SPiD_Identity)
            : new Promise((resolve) => {
                  window.addEventListener('identity-initialized', () => {
                      resolve(window.SPiD_Identity);
                  });
              });
    }
    return sdkPromise;
};

let identityCache;

/**
 * check if cache is valid
 */
const identityCacheIsExpired = () => {
    if (!identityCache || !identityCache.ok) {
        // no need to revalidate cache if not logged in
        return false;
    }

    const { serverTime, expiresIn } = identityCache.result;
    return Date.now() > (serverTime + expiresIn) * 1000;
};

/**
 * load session and put in cache
 */
let loadSessionPromise;
const loadSession = () =>
    timeout(
        getAccountSdk()
            .then((sdk) => sdk.hasSession())
            .then(
                (result) => ({
                    ok: true,
                    result,
                }),
                (error) => ({
                    ok: false,
                    error,
                }),
            )
            .then((newCache) => {
                identityCache = newCache;
            }),
        3000,
    );

/**
 * memoized load session, verify cache, and return either result or reject hasSession error
 * if cache is expired, clear cache and load session again
 */
const resolveIdentity = () =>
    (loadSessionPromise || (loadSessionPromise = loadSession()))
        .then(() => {
            if (identityCacheIsExpired()) {
                identityCache = undefined;
                return (loadSessionPromise = loadSession());
            }
        })
        .then(() => {
            return identityCache.ok
                ? identityCache.result
                : Promise.reject(identityCache.error);
        });

export const isLoggedIn = async () => {
    try {
        await resolveIdentity();
        preconnect('https://access.vg.no', 'use-credentials');
        return true;
    } catch {
        return false;
    }
};

/**
 * @return {Promise<import("@schibsted/account-sdk-browser/src/identity").HasSessionSuccessResponse | null, any>}
 */
export const hasSession = () => resolveIdentity();

export async function login(url, medium, term) {
    const sdk = await getAccountSdk();
    const state = setLoginFlowState({
        returnUrl: url,
        medium,
        term,
    });

    sdk.login({
        state,
    });
}

export default initSpid;
